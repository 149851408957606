import { EcommerceMainEnvironment, Site } from 'common-types';

export const environment: EcommerceMainEnvironment = {
  // required base properties
  production: true,
  isLocal: false,
  disableConsole: false,
  site: Site.Ecom, // used by shared components to indicate which site is making the request.
  recaptchaAppCheckKey: '6Lf11-soAAAAANJG6VJg2RLgqrsSclj6Bg1MHFgP', //(E-Comm Dev AppCheck)
  firebaseConfig: {
    apiKey: 'AIzaSyAM94JO678-pELRbrPm2j7ROKoTkqGZW38',
    authDomain: 'jfw-omni-dev.firebaseapp.com',
    projectId: 'jfw-omni-dev',
    storageBucket: 'jfw-omni-dev.appspot.com',
    messagingSenderId: '351933290246',
    appId: '1:351933290246:web:ec04dba418018a6ebff087',
    measurementId: 'G-J85F1X5ME4',
  },

  // rest api server urls
  calendar_rest_api_server_url: '/api/2/calendar',
  cart_rest_api_server_url: '/api/2/cart',
  checkout_rest_api_server_url: '/api/1/checkout',
  communication_rest_api_server_url: '/api/1/communication',
  communication_queue_rest_api_server_url: '/api/1/communicationQueue',
  contact_us_rest_api_server_url: '/api/1/contactUs',
  ecom_style_rest_api_server_url: '/api/2/ecomStyle',
  ecom_ensemble_rest_api_server_url: '/api/2/ecomEnsemble',
  measurement_rest_api_server_url: '/api/1/threeDLook',
  store_rest_api_server_url: '/api/1/store',
  tax_rest_api_server_url: '/api/1/avalaraTax',
  tax_rest_api_v2_server_url: '/api/2/avalaraTax',
  anon_user_rest_api_server_url: '/api/3/anonUser',
  user_rest_api_server_v3_url: '/api/3/user',
  update_user_account_server_url: '/api/1/updateUserAccount',
  payment_rest_api_server_url: '/api/2/elavonCreditCard',
  elastic_rest_api_server_url: '/api/2/elastic',
  ups_rest_api_server_url: '/api/1/ups',
  order_rest_api_server_url: '/api/1/order',
  token_validation_api_server_url: '/api/2/validation',
  event_invite_rest_api_server_url: '/api/2/invite',
  generate_sitemaps_rest_api_server_url: '/api/1/siteMap',

  envVersion: 'default',
  enableLocalStorage: true,
  max_event_local_storage_age: 60000, // milliseconds
  max_selected_event_local_storage_age: 30000,
  max_selected_cart_local_storage_age_secs: 3600,
  enableGuestEmulationMode: false,
  setPaymentDefaultsForTesting: false,
  dealerPortal: false,

  prod_mode_authorization_rules: true, //
  prod_mode_allow_normal_access: true, // when true this removes the /prod route and allows normal access to the site.
  prod_mode_refresh_key: 'tux4me@jims-dev', // Leave this blank when prod_mode_allow_normal_access is false.
  token_expire_minutes: 60, // TTL for token
  token_validation_expiration_minutes: 1, // How long between checks to see if token is valid.
  token_validation_min_allowed_ms_between_attempts: 30000, // throttling token refresh. This is the minimum time between token validation attempts. This is to prevent DDOS type stuff.

  baseHeaderTitle: "Jim's Formal Wear",

  recaptchaCheckboxKey: '6LdU3m0oAAAAAFYgNXw6uoeHhregHLuUiGMg__r-',
  recaptchaV3Key: '6Ld3A20oAAAAAPh2Lpff2L8j3wMhz2j09aFeIff2',
  googleApiKey: 'AIzaSyD-FOTLokVAo0MppMYQZ2ET7qlw_l4y3NM',
  googleMapId: '4469dc326e2ea127',
  googleTagManager: {
    containerId: 'GTM-N966JTL',
  },
  permittedExternalEmails: [
    'larryj@brixsystems.com',
    'alink2me@gmail.com',
    'erin.marie.prkns@gmail.com',
  ],
  threeDLook: {
    publicKey: 'NjMxOQ:1plxzy:--P9hw-Gw4zhBWHW7cetEPOK6p8',
    returnUrl: 'https://jfw-omni-ecom-dev.firebaseapp.com/measurements-return',
  },
  // cdnUrl:
  //   'https://us-central1-jfw-omni-dev.cloudfunctions.net/api_v1_imaging/api/1/imaging',
  cdnUrl:
    'https://firebasestorage.googleapis.com/v0/b/jfw-omni-dev-image-cache/o',

  ecom_prefix_for_email_and_sms_communication:
    'https://jfw-omni-ecom-dev.web.app',

  prefix_for_email_action_links: 'https://jfw-omni-ecom-dev.web.app',

  cloudFunctionsUrl: 'https://us-central1-jfw-omni-dev.cloudfunctions.net',

  showAddNewEventOnAccountPage: false,
  enableEventCreation: true,

  enableFindAStore: true,
  enableEventSync: true,

  allowCheckoutOverrideAmount: true,

  maintenance: false,

  forceFullScreenOnPages: ['/', '/home', '/event', '/home/'],
};
